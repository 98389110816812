import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { urlWithQuery, useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { useDialogState, useTabsState } from "../../../toolympus/components/primitives";
import { EditItemProps, useEditItem2 } from "../../../toolympus/api/useNewItem";
import { apiFetch } from "../../../toolympus/api/core";
import { useFieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { Arbitrator } from "../types";
import { useTags } from "../../../toolympus/components/Tags";
import { ArbitratorProfileData, useArbitratorProfileDetailsEdit, useArbitratorProfilePageConfig } from "../useArbitratorProfile";
import { useListSelection } from "../../../toolympus/hooks/useListSelection";
import { useTagSelection } from "../../../toolympus/components/Tags/RecordTagsEditPopup";
import { useAction, useItemActionWithConfirmation } from "../../../toolympus/api/useAction";

const ApiPath = "/api/arbitrator/admin";

const useArbitratorReview = (): EditItemProps<Arbitrator> & { editData?: ArbitratorProfileData | null } => {
  const review = useEditItem2<Arbitrator>({
    getApiPath: item => `${ApiPath}/${item._id}`,
    dontResetOnSave: true,
  });

  
  const detailsEdit = useArbitratorProfileDetailsEdit({ data: review.item || {} as Arbitrator, update: review.update });
  const pageConfig = useArbitratorProfilePageConfig();

  return {
    ...review,
    editData: review.item
      ? {
        ...review,
        changes: review.changes || {},
        hasChanges: review.hasChanges || false,
        data: review.item,
        ...detailsEdit,

        pageConfig,

        reload: () => Promise.resolve(review.item || {} as Arbitrator),
        isLoading: review.isLoading,
        remove: () => Promise.resolve({}),
        save: review.save,
        setData: review.setItem,


        isProfileLoaded: !!review.item,
        canEditProfile: true,
        profileStatus: {},
        resendConfirmationEmail: {
          buttonProps: { disabled: true, onClick: () => ({}), startIcon: null },
          canRun: false,
          isRunning: false,
          run: () => Promise.resolve({}),
        },

      }
      : null,
  }
}


export const useArbitratorsList = () => {
  const tabs = useTabsState([
    ["all", "All"],
    ["registration_not_complete", "Registration in progress"],
    ["registration_complete", "Registration complete"],
    ["excluded", "Unapproved"],
    ["active", "Active"],
  ], "all", "tab", "__j_arbitrator_list_cur_tab");

  const sorting = useFieldSorting({
    allowedFields: [
      "email",
      "rank",
      "organization",
      "job_title",
      "created_at",
      "registration_complete_at",
      "this_year_participation",
    ],
  })

  
  
  const data = useLoadedData<Arbitrator[]>(urlWithQuery(ApiPath, { view: tabs.current, "order-by": sorting.sort ? `${sorting.sort.field}:${sorting.sort.direction}` : undefined }), []);
  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

  const filter = useTextFilter<Arbitrator>(t => `${t.last_name} ${t.first_name} ${t.email} ${t.organization} ${t.job_title} ${t._id}`)
  const filteredData = filter.filterData(data.data);

const review = useArbitratorReview();

const remove = useItemActionWithConfirmation<Arbitrator, {}>(
  a => apiFetch(`${ApiPath}/${a._id}`, "delete").then(x => { review.cancel(); data.reload(); return {}; }), {
    title: "Remove judge",
    confirmationHint: "Please be careful: this action is like throwing yourself and the ring into lava - final, irrevocable, not undoable - you won't be able to glue that back together."
  });

  const changeEmail = useEditItem2<Arbitrator>({
    getApiPath: () => "",
    save: item => {
      return apiFetch<Arbitrator>(`${ApiPath}/${item._id}/email`, "put", { email: item.email })
        .then(updated => {
          data.reload();
          if(review.item && review.item._id === updated._id) {
            review.startEditing(updated);
          }
          return Promise.resolve(updated);
        })
    }
  })

  const excludeArbitrator = (teamId: string, isExcluded: boolean) => {
    apiFetch<Arbitrator>(`${ApiPath}/${teamId}`, "put", { is_excluded: isExcluded })
      .then(() => {
        data.reload();
        review.cancel();
      })
  }

  const updateRank = (id: string, rank: number) => {
    apiFetch<Arbitrator>(`${ApiPath}/${id}`, "put", { rank })
      .then(() => {
        data.reload();
      });
  }

  
  const selection = useListSelection<Arbitrator>(
    a => a._id,
    {
      items: filteredData,
    }
  );

  const conflicts = useTags("/api/conflict/tag", "arbiter");

  const massAssignTagsState = useDialogState();
  const massAssignTagsX = useTagSelection(conflicts.allTags || []);
  
  const massAssignAction = useAction(
    async () => {
      if(selection.selectedItems.length) {
        for (let i = 0; i < selection.selectedItems.length; i++) {
          const arb = selection.selectedItems[i];
          for (let tagIdx = 0; tagIdx < massAssignTagsX.selectedTags.length; tagIdx++) {
            const tag = massAssignTagsX.selectedTags[tagIdx];
            await apiFetch(`/api/conflict/tag/record/arbiter/${arb._id}/${tag._id}`, "post");
          }
        }
        massAssignTagsState.close();
        if(conflicts.reloadTags) {
          conflicts.reloadTags();
        }
        if(conflicts.reloadAllTags) {
          conflicts.reloadAllTags();
        }
      }
    }
  );

  return {
    ...data,
    data: filteredData,
    schema,
    filter,
    tabs,
    sorting,
    review: {
      ...review,
      save: () => review.save().then(x => { data.reload(); return x; })
    },
    remove,
    changeEmail,
    excludeArbitrator,
    updateRank,
    conflicts,
    selection,
    massAssignTags: {
      state: massAssignTagsState,
      ...massAssignAction,
      tags: massAssignTagsX,
    },
  }
}

export type ArbitratorListData = ReturnType<typeof useArbitratorsList>;
