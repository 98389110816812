import { useMemo } from "react";
import { Round } from "../../../toolympus/components/Contests/Grid/types";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { RoundInfo } from "../useRoundInfoEdit"
import { useAction } from "../../../toolympus/api/useAction";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { apiFetch, downloadFile } from "../../../toolympus/api/core";
import { usePlatformPage } from "../../Common/data/usePlatformPage";
import { getCustomBlocksData } from "../../../toolympus/components/CMS/Usage";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { RoundToScore, ScoreRoundData } from "../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { useScoreRound } from "./useScoreRound";
import { RoundScoringStatusData, useRoundScoringStatus } from "./useScoringStatus";
import { TimerData, useTimerStateBound } from "./Timer";
import { useRoundInfo } from "./useRoundInfoWithUpdates";
import { BailiffTeamTimingData, TeamsTimingData, useBailiffTeamTiming, useTeamsTiming } from "./useTeamsTiming";



const usePageConfig = () => {
  const pageInfo = usePlatformPage("participant-round-page");
  const messages = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    const bailiff = (pageData["bailiff_messages"] || {});
    const arbitrator = (pageData["arbitrator_messages"] || {});
    const team = (pageData["team_messages"] || {});
    const general = (pageData["general_messages"] || {});

    return {
      bailiff,
      arbitrator,
      team,
      general,
    };
  }, [pageInfo.data]);

  return {
    messages,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}

export type RoundPageConfig = ReturnType<typeof usePageConfig>;
export type RoundMessages = RoundPageConfig["messages"];

const useCheckin = (roundInfo: LoadedData<RoundInfo>) => {
  const { user } = useUser();

  const isCheckedIn = (roundInfo.data.checkins || {})[user?._id || ""];
  const checkIn = useAction(
    () => apiFetch<{}>(`/api/rounds-info/participant/${roundInfo.data.stage}/${roundInfo.data._id}/check-in`, "put", { checkin: true })
      .then(() => { roundInfo.reload(); return {}; }),
    !roundInfo.isLoading && !isCheckedIn);
  
  return {
    isLoading: roundInfo.isLoading || checkIn.isRunning,
    isCheckedIn,
    isRequireCheckIn: roundInfo.data._id && !isCheckedIn,
    checkIn,
  }
}

type CheckInData = ReturnType<typeof useCheckin>;


const useHelpRequest = (roundInfo: LoadedData<RoundInfo>, author?: string) => {
  const isHelpRequested = !!roundInfo.data.help_requests?.length

  const request = useEditItem2<{ issue: string }>({
    save: (item, changes) => {
      const withAuthor = author ? `${author}: ${item.issue}` : item.issue;
      return apiFetch(`/api/rounds-info/participant/${roundInfo.data.stage}/${roundInfo.data._id}/help`, "post", { issue: withAuthor });
    },

  });

  return {
    isHelpRequested,
    ...request,
    save: (extraChanges?: Partial<{ issue: string }>) => request.save(extraChanges).then(x => { roundInfo.reload(); return x; }),
  }
}

type HelpRequestData = ReturnType<typeof useHelpRequest>;



export interface ParticipantRoundData {
  roundInfo: RoundInfo;
  round: Round;
  isLoading: boolean;
  checkin?: CheckInData;
  teamsTiming?: TeamsTimingData;
  teamsTimingBailiffEdit?: BailiffTeamTimingData;
  helpRequest?: HelpRequestData;
  config: RoundPageConfig;
  downloadMemorial: (fileId: string) => void;
  scoreRound?: ScoreRoundData;
  scoringStatus?: RoundScoringStatusData;
  timer: TimerData;
}

const downloadMemorial = (stage: string, roundId: string, fileId: string) => downloadFile(`/api/rounds-info/participant/${stage}/${roundId}/document/${fileId}`);

export const useParticipantRoundData = (stage: string, roundId: string): ParticipantRoundData => {
  const { roundInfo } = useRoundInfo(`/api/rounds-info/${stage}/${roundId}`, true);
  const round = useLoadedData<Round>(`/api/rounds/stage/${stage}/round/${roundId}`, { } as Round);

  const config = usePageConfig();
  const helpRequest = useHelpRequest(roundInfo);

  const timer = useTimerStateBound(roundInfo.data, round.data);

  return {
    roundInfo: roundInfo.data,
    round: round.data,
    isLoading: roundInfo.isLoading || round.isLoading,
    config,
    helpRequest,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    timer,
  }
}

export const useArbitratorRoundData = (roundId: string): ParticipantRoundData => {
  const round = useLoadedData<Round>(`/api/rounds/arbiter/round/${roundId}`, { } as Round);
  const { roundInfo } = useRoundInfo(`/api/rounds-info/participant/${round.data.stage_code}/${roundId}`, !!round.data.stage_code);

  const { user } = useUser();
  const me = Object.values(round.data?.arbiters || {}).find(a => a._id === user?._id);

  const checkin = useCheckin(roundInfo);
  const helpRequest = useHelpRequest(roundInfo, me?.display_name);
  const config = usePageConfig();

  const scoreRound = useScoreRound(round as unknown as LoadedData<RoundToScore>);

  const timer = useTimerStateBound(roundInfo.data, round.data);

  return {
    roundInfo: roundInfo.data,
    round: round.data,
    isLoading: roundInfo.isLoading || round.isLoading,
    checkin,
    helpRequest,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    scoreRound,
    timer,
  }
}

export const useTeamRoundData = (roundId: string): ParticipantRoundData => {
  const rounds = useLoadedData<Round[]>(`/api/rounds/player/round`, []);
  const round = useMemo(() => {
    return rounds.data.find(r => r._id === roundId) || {} as Round;
  }, [rounds.data, roundId])
  const { roundInfo } = useRoundInfo(`/api/rounds-info/participant/${round?.stage_code}/${roundId}`, !!round?.stage_code);

  const { user } = useUser();
  const me = Object.values(round.players || {}).find(t => t._id === user?._id);

  const helpRequest = useHelpRequest(roundInfo, me?.display_name);
  const config = usePageConfig();
  const teamsTiming = useTeamsTiming(roundInfo, round);

  const timer = useTimerStateBound(roundInfo.data, round);

  return {
    roundInfo: roundInfo.data,
    round,
    helpRequest,
    isLoading: roundInfo.isLoading || rounds.isLoading,
    teamsTiming,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.stage_code, round._id, fileId),
    timer,
  }
}

export const useBailiffRoundData = (roundId: string): ParticipantRoundData => {
  const round = useLoadedData<Round>(`/api/rounds/bailiff/round/${roundId}`, { } as Round);
  const { roundInfo } = useRoundInfo(`/api/rounds-info/participant/${round.data.stage_code}/${roundId}`, !!round.data.stage_code);

  const checkin = useCheckin(roundInfo);
  const helpRequest = useHelpRequest(roundInfo);

  const scoringStatus = useRoundScoringStatus(round.data.stage_code, roundId);
  const teamsTimingBailiffEdit = useBailiffTeamTiming(roundInfo, round.data);
  
  const config = usePageConfig();

  const timer = useTimerStateBound(roundInfo.data, round.data);


  return {
    roundInfo: roundInfo.data,
    round: round.data,
    isLoading: roundInfo.isLoading || round.isLoading,
    checkin,
    helpRequest,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    scoringStatus,
    teamsTimingBailiffEdit,
    timer,
  }
}

export const useAdminRoundData = (stage: string, roundId: string): ParticipantRoundData => {
  const { roundInfo } = useRoundInfo(`/api/rounds-info/${stage}/${roundId}`, !!stage && !!roundId);
  const round = useLoadedData<Round>(`/api/rounds/stage/${stage}/round/${roundId}`, { } as Round, !!stage && !!roundId);
  
  const helpRequest = useHelpRequest(roundInfo);

  const scoringStatus = useRoundScoringStatus(round.data.stage_code, roundId);
  const teamsTimingBailiffEdit = useBailiffTeamTiming(roundInfo, round.data);

  const config = usePageConfig();

  const timer = useTimerStateBound(roundInfo.data, round.data);

  return {
    roundInfo: roundInfo.data,
    round: round.data,
    helpRequest,
    isLoading: roundInfo.isLoading || round.isLoading,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    scoringStatus,
    teamsTimingBailiffEdit,
    timer,
  }
}
