import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { Bailiff } from "../Bailiffs/types";

export interface TeamTimingTime {
  time?: number;
}
export interface TeamTimingSpeaker extends TeamTimingTime {
  speaker_name?: string;
  speaker_id?: string;
}

export interface TeamTiming {
  speaker_1: TeamTimingSpeaker;
  speaker_2: TeamTimingSpeaker;
  rebuttal: TeamTimingTime;
}


export interface ITimer {
  status: "running" | "paused" | "stopped";
  remaining_time?: number;
  end_time?: string;
  team?: number;
  timer_kind?: "main" | "rebuttal";
}

export interface RoundInfo {
  _id: string;
  stage: string;
  bailiff_id?: string;
  bailiff?: Bailiff;
  checkins?: Record<string, boolean>;
  teams_timing?: Record<string, TeamTiming>;
  help_requests?: string[];
  is_started?: boolean;
  is_finished?: boolean;
  timers?: { t1: ITimer | undefined, current_time: string };
}

export const useRoundInfoEdit = (stage: string, roundId: string, doLoad?: boolean) => {
  const data = useCrudItem<RoundInfo>(`/api/rounds-info/${stage}/${roundId}`, {
    noLoad: !doLoad,
    defaultValue: { _id: "", stage: "" },
  });

  return {
    ...data,
  }
}