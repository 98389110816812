import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment';
import { Button, IconButton, Typography } from '@mui/material';
import { BiPause, BiPlay, BiStop } from 'react-icons/bi';
import { BaseBiIconProps } from '../../Common/Icons';
import { ThemeSettings } from '../../../theme';
import { apiFetch } from '../../../toolympus/api/core';
import { ITimer, RoundInfo } from '../useRoundInfoEdit';
import { Buttons, FormGrid } from '../../../toolympus/components/primitives';
import { Round } from '../../../toolympus/components/Contests/Grid/types';

interface Props {
  
}

const TimerWrapperOut = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const TimerWrapper = styled.div<{ isHighlight?: boolean }>`
  position: relative;
  width: 220px;

  & .time {
    text-align: left;
    font-size: 4rem;
    font-weight: 700;
    width: 220px;
    font-family: ${ThemeSettings.typography.fontFamilyHeaders};
    color: ${props => props.isHighlight ? ThemeSettings.colors.primary : ThemeSettings.colors.shadeLight};
  }

  & .controls {
    position: absolute;
    left: 100%;
    bottom: 14px;
    width: max-content;

    & .play-buttons {
      gap: 0;
      justify-items: start;

      & .MuiButton-root {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    display: flex;
    flex-flow: column;
    align-items: center;

    & .time {
      text-align: center;
    }

    & .controls {
      position: static;

    }
    
  }
`;

const useTimerState = () => {
  const DefaultRemaining = 60*45;
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<Moment | null>(null);
  const [remainingTime, setRemainingTime] = useState<number>(DefaultRemaining);

  const play = () => { setIsRunning(true); setEndTime(moment().add({ seconds: remainingTime })); }
  const pause = () => { setIsRunning(false); }
  const stop = () => { setIsRunning(false); setRemainingTime(DefaultRemaining); setEndTime(null); }

  useEffect(() => {
    if(isRunning && endTime) {
      const interval = setInterval(() => {
        if(endTime) {
          const newRemaining = endTime.diff(moment(), "seconds");
          setRemainingTime(newRemaining);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRunning, endTime]);

  return {
    isRunning,
    isStopped: false,
    team: undefined,
    timerKind: undefined,
    remainingTime,
    remainingTimeMinSec: remainingTimeInMinSec(remainingTime),

    play,
    pause,
    stop,
  }
}



const DefaultTimerState: ITimer = {
  status: "stopped",
  remaining_time: 60*45,
}

export const remainingTimeInMinSec = (remaining: number): [number,number] => {
  const sign = remaining >= 0 ? 1 : -1;
  return [
    sign * Math.floor(Math.abs(remaining/60)),
    Math.abs(remaining%60),
  ];
}

const getTeamTime = (roundInfo: RoundInfo, round: Round, team: number, timeKind: "main" | "rebuttal") => {
  const player = round.players[team];
  const playerTiming = (roundInfo.teams_timing || {})[player._id];
  if(!playerTiming) {
    return DefaultTimerState.remaining_time;
  } else {
    if(timeKind === "rebuttal") {
      return (playerTiming.rebuttal.time || 0)*60;
    } else {
      return ((playerTiming.speaker_1.time || 0) + (playerTiming.speaker_2.time || 0))*60;
    }
  }
}

export const useTimerStateBound = (roundInfo: RoundInfo, round: Round) => {
  const DefaultRemaining = 60*45;
  const [timerState,setTimerState] = useState<ITimer>(DefaultTimerState);
  
  
  const [endTime, setEndTime] = useState<Moment | null>(null);
  const [remainingTime, setRemainingTime] = useState<number>(DefaultRemaining);

  const updateStateFromOutside = useCallback((timer: ITimer, refTime: string) => {
    setTimerState(timer);
    if(timer.status === "running") {
      const current = moment(refTime);
      const end = moment(timer.end_time);
      const remaining = end.diff(current, "seconds");
      setEndTime(moment().add({ seconds: remaining }));
    } else {
      setEndTime(null);
      setRemainingTime(timer.remaining_time || DefaultTimerState.remaining_time || 0);
    }
  }, []);

  const play = (team?: number, timerKind?: "main" | "rebuttal") => {
    const remaining = timerState.status === "paused"
      ? timerState.remaining_time
      : team
        ? getTeamTime(roundInfo, round, team, timerKind || "main")
        : DefaultTimerState.remaining_time;

    const data: any = { remaining_time: remaining };
    if(team) {
      data.team = team;
      data.timer_kind = timerKind || "main";
    }
    apiFetch<RoundInfo>(`/api/rounds-info/participant/${roundInfo.stage}/${roundInfo._id}/timer/t1/start`, "post", data)
      .then(ri => {
        const timer = ri.timers?.t1 || DefaultTimerState;
        updateStateFromOutside(timer, ri.timers?.current_time as string);
      })
   }
  const pause = () => {
    apiFetch<RoundInfo>(`/api/rounds-info/participant/${roundInfo.stage}/${roundInfo._id}/timer/t1/pause`, "post")
      .then(ri => {
        const timer = ri.timers?.t1 || DefaultTimerState;
        updateStateFromOutside(timer, ri.timers?.current_time as string);
      })
  }
  const stop = () => {
    apiFetch<RoundInfo>(`/api/rounds-info/participant/${roundInfo.stage}/${roundInfo._id}/timer/t1/stop`, "post", { remaining_time: DefaultTimerState.remaining_time })
      .then(ri => {
        const timer = ri.timers?.t1 || DefaultTimerState;
        updateStateFromOutside(timer, ri.timers?.current_time as string);
      })
  }

  const isRunning = timerState.status === "running";

  useEffect(() => {
    if(isRunning && endTime) {
      const interval = setInterval(() => {
        if(endTime) {
          const newRemaining = endTime.diff(moment(), "seconds");
          setRemainingTime(newRemaining);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRunning, endTime]);

  const timerStateIncoming = roundInfo.timers?.t1 || DefaultTimerState;
  const refTime = roundInfo.timers?.current_time as string;
  useEffect(() => {
    updateStateFromOutside(timerStateIncoming, refTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerStateIncoming, refTime]);

  return {
    isRunning,
    isStopped: timerState.status === "stopped",
    remainingTime,
    remainingTimeMinSec: remainingTimeInMinSec(remainingTime),
    team: timerState.team,
    timerKind: timerState.timer_kind,

    play,
    pause,
    stop,
  }
}

export type TimerData = ReturnType<typeof useTimerStateBound>;

export const padTimeDigits = (n: number) => {
  const timePart = n.toString();
  if(timePart.length > 1) {
    return timePart;
  } else {
    return `0${timePart}`;
  }
}

export const Timer = (props: { data: TimerData, isControlsAvailable?: boolean }) => {
  const timer = props.data;

  const [mbase,sbase] = timer.remainingTimeMinSec;
  const [m,s] = mbase < -60 ? [-60,0] : [mbase,sbase];

  return (
    <TimerWrapperOut>
      <TimerWrapper isHighlight={m < 3}>
        <Typography className="time">{m === 0 && timer.remainingTime < 0 ? "-" : ""}{padTimeDigits(m)}:{padTimeDigits(s)}</Typography>
        {props.isControlsAvailable && <Buttons className="controls">
          {timer.isStopped
            ? <>
              <FormGrid className="play-buttons" columns="1fr" gap="dense" noMargin>
                <Button onClick={() => timer.play(1)} color="primary" startIcon={<BiPlay {...BaseBiIconProps} />}>Applicant</Button>
                <Button onClick={() => timer.play(1, "rebuttal")} color="primary" startIcon={<BiPlay {...BaseBiIconProps} />}>A Rebuttal</Button>
              </FormGrid>
              <FormGrid className="play-buttons" columns="1fr" gap="dense" noMargin>
                <Button onClick={() => timer.play(2)} color="primary" startIcon={<BiPlay {...BaseBiIconProps} />}>Respondent</Button>
                <Button onClick={() => timer.play(2, "rebuttal")} color="primary" startIcon={<BiPlay {...BaseBiIconProps} />}>R Rebuttal</Button>
              </FormGrid>
            </>
            : <>
              {!timer.isRunning && <IconButton color="primary" onClick={() => timer.play()}><BiPlay {...BaseBiIconProps} /></IconButton>}
              {!timer.isRunning && <div />}
              {timer.isRunning && <IconButton color="primary" onClick={() => timer.pause()}><BiPause {...BaseBiIconProps} /></IconButton>}
              {timer.isRunning && <IconButton color="primary" onClick={() => timer.stop()}><BiStop {...BaseBiIconProps} /></IconButton>}
            </>}
        </Buttons>}
      </TimerWrapper>
    </TimerWrapperOut>
  );
}

export const TimerStub = (props: Props) => {
  const timer = useTimerState();

  return <Timer data={timer} />
}
