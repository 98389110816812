import React from 'react';
import styled from '@emotion/styled';
import { useTeamDocumentsEdit } from './useTeamDocumentsEdit';
import { PageHeader } from '../../Common/PageHeader';
import { PageHeaderMessage, PagePanel } from '../../Common/PageHarness';
import { PageViewer } from '../../Common/PageViewer';
import { FormGrid } from '../../../toolympus/components/primitives';
import { FileEditUnbound } from '../../../toolympus/components/files/FileEdit';
import { FileInfo } from '../../../toolympus/components/files';
import { ThemeSettings } from '../../../theme';
import { useTeamRoundsFeedback } from './useTeamRoundsFeedback';
import { TeamRoundsFeedbackForm } from './TeamRoundsFeedbackForm';

const PrintStyles = `
  @media print {
    #appcontentwrapper {
      max-height: unset;
    }

    .memorials-panel, .page-header, .MuiDrawer-root, [aria-label="open drawer"] {
      display: none;
    }

    .feedback-panel {
      box-shadow: none;
    }
  }
`;

const MemorialsWrapper = styled(FormGrid)<{ isFile1Present?: boolean, isFile2Present?: boolean }>`
  & .file-editor {
    & > div {
      box-shadow: ${ThemeSettings.sizes.shadow.panel} ${ThemeSettings.colors.shadow.button};
      border-width: 0;
      min-height: 52px;

      & > .MuiSvgIcon-root {
        color: ${ThemeSettings.colors.primary};
      }
    }

    &:nth-child(1) {
      & > div {
        background: ${props => props.isFile1Present ? ThemeSettings.colors.background : ThemeSettings.colors.backgroundAlt};
      }
    }
    &:nth-child(2) {
      & > div {
        background: ${props => props.isFile2Present ? ThemeSettings.colors.background : ThemeSettings.colors.backgroundAlt};
      }
    }
  }
`;
MemorialsWrapper.defaultProps = { columns: "1fr 1fr", forceEvenColumns: true };

export const TeamDocumentsPage = () => {
  const data = useTeamDocumentsEdit();

  const feedback = useTeamRoundsFeedback();

  return (<>
    <PageHeader title={data.pageConfig.messages.title || "..."} isLoading={data.isLoading || data.pageConfig.isLoading}>
      {data.pageConfig.messages?.header_message &&
        <PageHeaderMessage>{data.pageConfig.messages?.header_message}</PageHeaderMessage>}
    </PageHeader>

    <PagePanel fillPage={feedback.isEmpty} className="memorials-panel">
      <PageViewer content={data.pageConfig.pageInfo.page.content} key={data.pageConfig.pageInfo.page._id} />
      
      <MemorialsWrapper isFile1Present={!!data.data.memorial_1_id} isFile2Present={!!data.data.memorial_2_id}>
        <FileEditUnbound
          data={{
            download: () => data.downloadMemorial("memorial_1_id"),
            file: data.data.memorial_1_id
              ? { _id: data.data.memorial_1_id, filename: data.pageConfig.messages.memorial_1_label } as FileInfo
              : null,
            isLoading: data.isFileLoading["memorial_1_id"],
            remove: () => data.removeMemorial("memorial_1_id").then(() => {}),
            upload: file => data.uploadMemorial("memorial_1_id", file),
            reset: () => {},
            }}
            disabled={!data.pageConfig.state.team_can_upload_documents}
            placeholder={`Drop ${data.pageConfig.messages.memorial_1_label} here or click to upload`}
          />

        <FileEditUnbound
          data={{
            download: () => data.downloadMemorial("memorial_2_id"),
            file: data.data.memorial_2_id
              ? { _id: data.data.memorial_2_id, filename: data.pageConfig.messages.memorial_2_label } as FileInfo
              : null,
            isLoading: data.isFileLoading["memorial_2_id"],
            remove: () => data.removeMemorial("memorial_2_id").then(() => {}),
            upload: file => data.uploadMemorial("memorial_2_id", file),
            reset: () => {},
            }}
            disabled={!data.pageConfig.state.team_can_upload_documents}
            placeholder={`Drop ${data.pageConfig.messages.memorial_2_label} here or click to upload`}
          />
      </MemorialsWrapper>
    </PagePanel>

    {!feedback.isEmpty && <PagePanel className="feedback-panel">
      <TeamRoundsFeedbackForm data={feedback} messages={data.pageConfig.messages} />
    </PagePanel>}

    <style dangerouslySetInnerHTML={{ __html: PrintStyles }}>
      
    </style>
  </>);
}
