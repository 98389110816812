import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { urlWithQuery, useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { Team } from "../types"
import { useTabsState } from "../../../toolympus/components/primitives";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { apiFetch, downloadFile } from "../../../toolympus/api/core";
import { useFieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { useTags } from "../../../toolympus/components/Tags";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";

const ApiPath = "/api/team/admin";

export const useTeamsList = () => {
  const tabs = useTabsState([
    ["all", "All"],
    ["registration_not_complete", "Registration in progress"],
    ["registration_complete", "Registration complete"],
    ["excluded", "Excluded"],
    ["active", "Active"],
  ], "all", "tab", "__j_team_list_cur_tab");

  const sorting = useFieldSorting({
    allowedFields: [
      "teamnbr",
      "university",
      "email",
      "created_at",
      "registration_complete_at",
    ],
  })
  
  const data = useLoadedData<Team[]>(urlWithQuery(ApiPath, { view: tabs.current, "order-by": sorting.sort ? `${sorting.sort.field}:${sorting.sort.direction}` : undefined }), []);
  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

  const filter = useTextFilter<Team>(t => `${t.teamnbr} ${t.email} ${t.university} ${t.contact?.last_name} ${t._id}`)
  const filteredData = filter.filterData(data.data);

  const review = useEditItem2<Team>({
    getApiPath: item => `${ApiPath}/${item._id}`,
    dontResetOnSave: true,
  });

  const remove = useItemActionWithConfirmation<Team, {}>(
    a => apiFetch(`${ApiPath}/${a._id}`, "delete").then(x => { review.cancel(); data.reload(); return {}; }), {
      title: "Remove team",
      confirmationHint: "Please be careful: this action is like throwing yourself and the ring into lava - final, irrevocable, not undoable - you won't be able to glue that back together."
    });

  const changeEmail = useEditItem2<Team>({
    getApiPath: () => "",
    save: item => {
      return apiFetch<Team>(`${ApiPath}/${item._id}/email`, "put", { email: item.email })
        .then(updated => {
          data.reload();
          if(review.item && review.item._id === updated._id) {
            review.startEditing(updated);
          }
          return Promise.resolve(updated);
        })
    }
  })

  const excludeTeam = (teamId: string, isExcluded: boolean) => {
    apiFetch<Team>(`${ApiPath}/${teamId}`, "put", { is_excluded: isExcluded })
      .then(() => {
        data.reload();
        review.cancel();
      })
  }

  const conflicts = useTags("/api/conflict/tag", "team");

  const downloadMemorial = (fileId: string) => {
    return downloadFile(`/api/team/memorial/${fileId}`);
  }

  return {
    ...data,
    data: filteredData,
    schema,
    filter,
    tabs,
    sorting,
    review: {
      ...review,
      save: (extraChanges?: Partial<Team>) => review.save(extraChanges).then(r => { data.reload(); return r; }),
    },
    remove,
    changeEmail,
    excludeTeam,
    conflicts,
    downloadMemorial,
  }
}

export type TeamListData = ReturnType<typeof useTeamsList>;
