import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Person } from '../types';
import { FormGrid, Tooltip } from '../../../toolympus/components/primitives';
import { ThemeSettings } from '../../../theme';
import { IconButton, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import { MoreVert, Telegram, WhatsApp } from '@mui/icons-material';
import { ValidationErrors } from '../../../toolympus/components/schemed';
import { FileEditUnbound } from '../../../toolympus/components/files/FileEdit';
import { TeamProfileData } from './useTeamProfile';
import { ImageDisplay } from '../../../toolympus/components/files/ImageDisplay';

const PersonWrapper = styled(FormGrid)`
  padding-left: 20px;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};

  & .img-wrapper, & .avatar-placeholder, & .file-editor {
    grid-row: 1 / span 2;
    grid-column: 3;
    width: 150px;
    height: 150px;

    & .placeholder {
      height: 100%;
    }

    @media ${ThemeSettings.sizes.breakpoints.sm.down} {
      justify-self: center;
    }
  }

  & .img-wrapper {
    border-radius: 75px;
    overflow: hidden;
    box-shadow: ${ThemeSettings.sizes.shadow.button} ${ThemeSettings.colors.shadow.button};

    & img {
      object-position: center center;
    }
  }
`;
PersonWrapper.defaultProps = { columns: "1fr 1fr 150px", gap: "dense", suppressContentColumnsOnSm: true };

interface Props {
  item: Person;
  update: (changes: Partial<Person>) => void;
  remove?: () => void;
  readOnly?: boolean;
  avatarReadOnly?: boolean;
  errors?: ValidationErrors;
  avatars?: TeamProfileData["avatars"];
}

export const PersonEditor = (props: Props) => {
  const { item, readOnly, avatarReadOnly, update, remove, errors } = props;
  const [menuAnchor,setMenuAnchor] = useState<any>(null);

  return (
    <PersonWrapper>
      <TextField
        label="First name"
        required
        value={item.first_name || ""}
        InputProps={{ readOnly }}
        onChange={e => update({ first_name: e.target.value })}
        error={errors?.fieldHasErrors("first_name")}
        />
      <TextField
        label="Last name"
        required
        value={item.last_name || ""}
        error={errors?.fieldHasErrors("last_name")}
        InputProps={{
          readOnly,
          endAdornment: readOnly && remove ? null : (
            <InputAdornment position="end">
              <IconButton size="small" onClick={e => setMenuAnchor(e.target)}><MoreVert /></IconButton>
              <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)} keepMounted>
                {remove && <MenuItem onClick={() => remove()}>remove</MenuItem>}
              </Menu>
            </InputAdornment>
          )
        }}
        onChange={e => update({ last_name: e.target.value })}
        />

      {item.avatar_url
        ? <ImageDisplay src={item.avatar_url} remove={() => update({ avatar_url: "" })} />
        : avatarReadOnly
          ? <div className="avatar-placeholder" />
          : <FileEditUnbound
              placeholder="Drop photo here or click to select"
              data={{
                download: () => {},
                file: null,
                isLoading: props.avatars?.isAvatarUploading || false,
                remove: () => Promise.resolve(),
                upload: f => props.avatars ? props.avatars?.uploadAvatar(f).then(fi => { update({ avatar_url: `/avatar/${fi._id}/${fi.filename}`}); return fi; }) : Promise.resolve({ _id: "", contenttype: "", filename: "", kind: "", owner_id: "", url: "" }),
                reset: () => {},
              }}
            />}

      <TextField
        label="Email"
        type="email"
        required
        value={item.email || ""}
        InputProps={{ readOnly }}
        onChange={e => update({ email: e.target.value })}
        error={errors?.fieldHasErrors("email")}
        />

      <TextField
        label="Phone"
        required
        value={item.phone || ""}
        InputProps={{
          readOnly,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip text={`Telegram ${item.telegram_available ? "" : "not "}available`}><IconButton size="small" color={item.telegram_available ? "primary" : undefined} onClick={() => !readOnly && update({ telegram_available: !item.telegram_available })}><Telegram /></IconButton></Tooltip>
              <Tooltip text={`WhatsApp ${item.whatsapp_available ? "" : "not "}available`}><IconButton size="small" color={item.whatsapp_available ? "primary" : undefined} onClick={() => !readOnly && update({ whatsapp_available: !item.whatsapp_available })}><WhatsApp /></IconButton></Tooltip>
            </InputAdornment>
          )
        }}
        onChange={e => update({ phone: e.target.value })}
        error={errors?.fieldHasErrors("phone")}
        />
    </PersonWrapper>
  );
}
